/* tslint:disable */
/* eslint-disable */
/**
 * Chemically Peculiar Stars Database OpenAPI definitions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DataSource
 */
export interface DataSource {
    /**
     * 
     * @type {number}
     * @memberof DataSource
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof DataSource
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DataSource
     */
    fullName: string;
    /**
     * 
     * @type {number}
     * @memberof DataSource
     */
    year: number;
    /**
     * 
     * @type {string}
     * @memberof DataSource
     */
    bibcode: string;
    /**
     * 
     * @type {string}
     * @memberof DataSource
     */
    description: string;
}

/**
 * Check if a given object implements the DataSource interface.
 */
export function instanceOfDataSource(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "fullName" in value;
    isInstance = isInstance && "year" in value;
    isInstance = isInstance && "bibcode" in value;
    isInstance = isInstance && "description" in value;

    return isInstance;
}

export function DataSourceFromJSON(json: any): DataSource {
    return DataSourceFromJSONTyped(json, false);
}

export function DataSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataSource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'fullName': json['fullName'],
        'year': json['year'],
        'bibcode': json['bibcode'],
        'description': json['description'],
    };
}

export function DataSourceToJSON(value?: DataSource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'fullName': value.fullName,
        'year': value.year,
        'bibcode': value.bibcode,
        'description': value.description,
    };
}

