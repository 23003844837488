/* tslint:disable */
/* eslint-disable */
/**
 * Chemically Peculiar Stars Database OpenAPI definitions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ExportCsvForm,
} from '../models';
import {
    ExportCsvFormFromJSON,
    ExportCsvFormToJSON,
} from '../models';

export interface DownloadStarsCSVRequest {
    ExportCsvForm: ExportCsvForm;
}

export interface GetExtendedStarTxtRequest {
    id: number;
}

/**
 * 
 */
export class ExportControllerApi extends runtime.BaseAPI {

    /**
     * Selected stars are exported into CSV file together with exhaustive information gathered from database.
     * Export specified stars into CSV file.
     */
    async downloadStarsCSVRaw(requestParameters: DownloadStarsCSVRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.ExportCsvForm === null || requestParameters.ExportCsvForm === undefined) {
            throw new runtime.RequiredError('ExportCsvForm','Required parameter requestParameters.ExportCsvForm was null or undefined when calling downloadStarsCSV.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/export/csv`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExportCsvFormToJSON(requestParameters.ExportCsvForm),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Selected stars are exported into CSV file together with exhaustive information gathered from database.
     * Export specified stars into CSV file.
     */
    async downloadStarsCSV(requestParameters: DownloadStarsCSVRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.downloadStarsCSVRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * All stars are exported into CSV file together with exhaustive information gathered from database.
     * Export all stars into CSV file.
     */
    async downloadStarsCSV1Raw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/export/csv/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * All stars are exported into CSV file together with exhaustive information gathered from database.
     * Export all stars into CSV file.
     */
    async downloadStarsCSV1(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.downloadStarsCSV1Raw(initOverrides);
        return await response.value();
    }

    /**
     * Response contains exhaustive information in plain text form about the star both from the database and external services. IMPORTANT: Querying external sources may take some time. Information obtained include: - external data - coordinates - identifier - photometry - proper motions and parallaxes
     * Get all information (extended version) about specified star.
     */
    async getExtendedStarTxtRaw(requestParameters: GetExtendedStarTxtRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getExtendedStarTxt.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/export/txt`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Response contains exhaustive information in plain text form about the star both from the database and external services. IMPORTANT: Querying external sources may take some time. Information obtained include: - external data - coordinates - identifier - photometry - proper motions and parallaxes
     * Get all information (extended version) about specified star.
     */
    async getExtendedStarTxt(requestParameters: GetExtendedStarTxtRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getExtendedStarTxtRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Return list of all supported (possible) empty value representations. Only these representations can be used for export.
     * Return list of all supported (possible) empty value representations.
     */
    async getSupportedEmptyValueRepresentationsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/export/csv/empty-value-repr`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Return list of all supported (possible) empty value representations. Only these representations can be used for export.
     * Return list of all supported (possible) empty value representations.
     */
    async getSupportedEmptyValueRepresentations(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getSupportedEmptyValueRepresentationsRaw(initOverrides);
        return await response.value();
    }

}
