/* tslint:disable */
/* eslint-disable */
/**
 * Chemically Peculiar Stars Database OpenAPI definitions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ExternalDetails,
} from '../models';
import {
    ExternalDetailsFromJSON,
    ExternalDetailsToJSON,
} from '../models';

export interface GetIdentifiersRequest {
    name: string;
}

export interface GetSimbadExternalDetailsRequest {
    name: string;
}

export interface GetVizierMetadataRequest {
    name: string;
}

/**
 * 
 */
export class ExternalServicesControllerApi extends runtime.BaseAPI {

    /**
     * Response contains list of identifiers obtained from AstroSearcher application. IMPORTANT: Querying external sources may take some time.
     * Get identifiers from external sources (AstroSearcher).
     */
    async getIdentifiersRaw(requestParameters: GetIdentifiersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getIdentifiers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/external/astrosearcher/identifiers/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Response contains list of identifiers obtained from AstroSearcher application. IMPORTANT: Querying external sources may take some time.
     * Get identifiers from external sources (AstroSearcher).
     */
    async getIdentifiers(requestParameters: GetIdentifiersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getIdentifiersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Response contains Simbad data obtained from external sources (AstroSearcher). IMPORTANT: Querying external sources may take some time.
     * Get data about specified object from Simbad using AstroSearcher.
     */
    async getSimbadExternalDetailsRaw(requestParameters: GetSimbadExternalDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExternalDetails>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getSimbadExternalDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/external/astrosearcher/simbad/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExternalDetailsFromJSON(jsonValue));
    }

    /**
     * Response contains Simbad data obtained from external sources (AstroSearcher). IMPORTANT: Querying external sources may take some time.
     * Get data about specified object from Simbad using AstroSearcher.
     */
    async getSimbadExternalDetails(requestParameters: GetSimbadExternalDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExternalDetails> {
        const response = await this.getSimbadExternalDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Response contains Vizier metadata obtained from external sources (AstroSearcher). IMPORTANT: Querying external sources may take some time.
     * Get Vizier metadata about specified object using AstroSearcher.
     */
    async getVizierMetadataRaw(requestParameters: GetVizierMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExternalDetails>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getVizierMetadata.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/external/astrosearcher/vizier-metadata/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExternalDetailsFromJSON(jsonValue));
    }

    /**
     * Response contains Vizier metadata obtained from external sources (AstroSearcher). IMPORTANT: Querying external sources may take some time.
     * Get Vizier metadata about specified object using AstroSearcher.
     */
    async getVizierMetadata(requestParameters: GetVizierMetadataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExternalDetails> {
        const response = await this.getVizierMetadataRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
