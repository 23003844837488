/* tslint:disable */
/* eslint-disable */
/**
 * Chemically Peculiar Stars Database OpenAPI definitions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DataSourceBasicInfo
 */
export interface DataSourceBasicInfo {
    /**
     * 
     * @type {number}
     * @memberof DataSourceBasicInfo
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof DataSourceBasicInfo
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof DataSourceBasicInfo
     */
    year: number;
    /**
     * 
     * @type {string}
     * @memberof DataSourceBasicInfo
     */
    bibcode: string;
}

/**
 * Check if a given object implements the DataSourceBasicInfo interface.
 */
export function instanceOfDataSourceBasicInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "year" in value;
    isInstance = isInstance && "bibcode" in value;

    return isInstance;
}

export function DataSourceBasicInfoFromJSON(json: any): DataSourceBasicInfo {
    return DataSourceBasicInfoFromJSONTyped(json, false);
}

export function DataSourceBasicInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataSourceBasicInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'year': json['year'],
        'bibcode': json['bibcode'],
    };
}

export function DataSourceBasicInfoToJSON(value?: DataSourceBasicInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'year': value.year,
        'bibcode': value.bibcode,
    };
}

