/* tslint:disable */
/* eslint-disable */
/**
 * Chemically Peculiar Stars Database OpenAPI definitions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LightCurveMeasurement
 */
export interface LightCurveMeasurement {
    /**
     * 
     * @type {number}
     * @memberof LightCurveMeasurement
     */
    time?: number;
    /**
     * 
     * @type {number}
     * @memberof LightCurveMeasurement
     */
    value?: number;
}

/**
 * Check if a given object implements the LightCurveMeasurement interface.
 */
export function instanceOfLightCurveMeasurement(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LightCurveMeasurementFromJSON(json: any): LightCurveMeasurement {
    return LightCurveMeasurementFromJSONTyped(json, false);
}

export function LightCurveMeasurementFromJSONTyped(json: any, ignoreDiscriminator: boolean): LightCurveMeasurement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'time': !exists(json, 'time') ? undefined : json['time'],
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function LightCurveMeasurementToJSON(value?: LightCurveMeasurement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'time': value.time,
        'value': value.value,
    };
}

