/* tslint:disable */
/* eslint-disable */
/**
 * Chemically Peculiar Stars Database OpenAPI definitions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttributeDefinition } from './AttributeDefinition';
import {
    AttributeDefinitionFromJSON,
    AttributeDefinitionFromJSONTyped,
    AttributeDefinitionToJSON,
} from './AttributeDefinition';

/**
 * 
 * @export
 * @interface MagnitudeAttribute
 */
export interface MagnitudeAttribute {
    /**
     * 
     * @type {number}
     * @memberof MagnitudeAttribute
     */
    id: number;
    /**
     * 
     * @type {AttributeDefinition}
     * @memberof MagnitudeAttribute
     */
    attributeDefinition: AttributeDefinition;
    /**
     * 
     * @type {string}
     * @memberof MagnitudeAttribute
     */
    value: string;
    /**
     * 
     * @type {boolean}
     * @memberof MagnitudeAttribute
     */
    defined?: boolean;
}

/**
 * Check if a given object implements the MagnitudeAttribute interface.
 */
export function instanceOfMagnitudeAttribute(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "attributeDefinition" in value;
    isInstance = isInstance && "value" in value;

    return isInstance;
}

export function MagnitudeAttributeFromJSON(json: any): MagnitudeAttribute {
    return MagnitudeAttributeFromJSONTyped(json, false);
}

export function MagnitudeAttributeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MagnitudeAttribute {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'attributeDefinition': AttributeDefinitionFromJSON(json['attributeDefinition']),
        'value': json['value'],
        'defined': !exists(json, 'defined') ? undefined : json['defined'],
    };
}

export function MagnitudeAttributeToJSON(value?: MagnitudeAttribute | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'attributeDefinition': AttributeDefinitionToJSON(value.attributeDefinition),
        'value': value.value,
        'defined': value.defined,
    };
}

