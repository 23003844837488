/* tslint:disable */
/* eslint-disable */
/**
 * Chemically Peculiar Stars Database OpenAPI definitions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExportCsvForm
 */
export interface ExportCsvForm {
    /**
     * 
     * @type {Array<number>}
     * @memberof ExportCsvForm
     */
    starIdsToExport?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof ExportCsvForm
     */
    emptyValueRepresentation?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExportCsvForm
     */
    exportAttributes?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExportCsvForm
     */
    exportIdentifiers?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExportCsvForm
     */
    exportMagnitudes?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExportCsvForm
     */
    exportMotions?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExportCsvForm
     */
    exportRadialVelocities?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ExportCsvForm
     */
    defaultEMPTYVALUEREPRESENTATION?: string;
}

/**
 * Check if a given object implements the ExportCsvForm interface.
 */
export function instanceOfExportCsvForm(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExportCsvFormFromJSON(json: any): ExportCsvForm {
    return ExportCsvFormFromJSONTyped(json, false);
}

export function ExportCsvFormFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExportCsvForm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'starIdsToExport': !exists(json, 'starIdsToExport') ? undefined : json['starIdsToExport'],
        'emptyValueRepresentation': !exists(json, 'emptyValueRepresentation') ? undefined : json['emptyValueRepresentation'],
        'exportAttributes': !exists(json, 'exportAttributes') ? undefined : json['exportAttributes'],
        'exportIdentifiers': !exists(json, 'exportIdentifiers') ? undefined : json['exportIdentifiers'],
        'exportMagnitudes': !exists(json, 'exportMagnitudes') ? undefined : json['exportMagnitudes'],
        'exportMotions': !exists(json, 'exportMotions') ? undefined : json['exportMotions'],
        'exportRadialVelocities': !exists(json, 'exportRadialVelocities') ? undefined : json['exportRadialVelocities'],
        'defaultEMPTYVALUEREPRESENTATION': !exists(json, 'default_EMPTY_VALUE_REPRESENTATION') ? undefined : json['default_EMPTY_VALUE_REPRESENTATION'],
    };
}

export function ExportCsvFormToJSON(value?: ExportCsvForm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'starIdsToExport': value.starIdsToExport,
        'emptyValueRepresentation': value.emptyValueRepresentation,
        'exportAttributes': value.exportAttributes,
        'exportIdentifiers': value.exportIdentifiers,
        'exportMagnitudes': value.exportMagnitudes,
        'exportMotions': value.exportMotions,
        'exportRadialVelocities': value.exportRadialVelocities,
        'default_EMPTY_VALUE_REPRESENTATION': value.defaultEMPTYVALUEREPRESENTATION,
    };
}

