/* tslint:disable */
/* eslint-disable */
/**
 * Chemically Peculiar Stars Database OpenAPI definitions
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttributeDefinition } from './AttributeDefinition';
import {
    AttributeDefinitionFromJSON,
    AttributeDefinitionFromJSONTyped,
    AttributeDefinitionToJSON,
} from './AttributeDefinition';
import type { DataSource } from './DataSource';
import {
    DataSourceFromJSON,
    DataSourceFromJSONTyped,
    DataSourceToJSON,
} from './DataSource';
import type { Star } from './Star';
import {
    StarFromJSON,
    StarFromJSONTyped,
    StarToJSON,
} from './Star';

/**
 * 
 * @export
 * @interface StarDatasourceAttribute
 */
export interface StarDatasourceAttribute {
    /**
     * 
     * @type {number}
     * @memberof StarDatasourceAttribute
     */
    id: number;
    /**
     * 
     * @type {AttributeDefinition}
     * @memberof StarDatasourceAttribute
     */
    attributeDefinition: AttributeDefinition;
    /**
     * 
     * @type {DataSource}
     * @memberof StarDatasourceAttribute
     */
    datasource: DataSource;
    /**
     * 
     * @type {Star}
     * @memberof StarDatasourceAttribute
     */
    star?: Star;
    /**
     * 
     * @type {string}
     * @memberof StarDatasourceAttribute
     */
    value: string;
    /**
     * 
     * @type {boolean}
     * @memberof StarDatasourceAttribute
     */
    defined?: boolean;
}

/**
 * Check if a given object implements the StarDatasourceAttribute interface.
 */
export function instanceOfStarDatasourceAttribute(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "attributeDefinition" in value;
    isInstance = isInstance && "datasource" in value;
    isInstance = isInstance && "value" in value;

    return isInstance;
}

export function StarDatasourceAttributeFromJSON(json: any): StarDatasourceAttribute {
    return StarDatasourceAttributeFromJSONTyped(json, false);
}

export function StarDatasourceAttributeFromJSONTyped(json: any, ignoreDiscriminator: boolean): StarDatasourceAttribute {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'attributeDefinition': AttributeDefinitionFromJSON(json['attributeDefinition']),
        'datasource': DataSourceFromJSON(json['datasource']),
        'star': !exists(json, 'star') ? undefined : StarFromJSON(json['star']),
        'value': json['value'],
        'defined': !exists(json, 'defined') ? undefined : json['defined'],
    };
}

export function StarDatasourceAttributeToJSON(value?: StarDatasourceAttribute | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'attributeDefinition': AttributeDefinitionToJSON(value.attributeDefinition),
        'datasource': DataSourceToJSON(value.datasource),
        'star': StarToJSON(value.star),
        'value': value.value,
        'defined': value.defined,
    };
}

